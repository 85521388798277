<template>
  <Layout class="withdrawal">
    <BHeader v-show="false">
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.gameSourceId"
            clearable
            placeholder="游戏ID"
            @change="formData.gameSourceId = formData.gameSourceId.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          累计邀请注册好友
        </div>
        <div class="num">
          {{ statisticsInfo.inviteFriendsCount | numberFilter }}  <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          累计邀请付费好友
        </div>
        <div class="num">
          {{ statisticsInfo.paidFriendsCount | numberFilter }}  <span class="unit">个</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          累计邀请产生收入
        </div>
        <div class="num">
          <span class="unit">$</span>  {{ statisticsInfo.invitationsRevenue | numberFilter }}
        </div>
      </div>
      <div class="items">
        <div class="name">
          累计产生奖励
        </div>
        <div class="num">
          <span class="unit">$</span> {{ statisticsInfo.accumulateRewards | numberFilter }}
        </div>
      </div>
    </div>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="邀请人ID"
        prop="inviteUserIdStr"
        width="180px"
      />
      <el-table-column
        label="完成注册的好友"
        prop="registereFriendsCount"
        width="120px"
      />
      <el-table-column
        label="完成体验的好友"
        prop="experienceFriendsCount"
        width="120px"
      />
      <el-table-column
        label="完成付费的好友"
        prop="paidFriendsCount"
        width="120px"
      />
      <el-table-column
        label="被邀请人累计付费"
        prop="cumulativePaymentAmount"
        width="180px"
      />
      <el-table-column
        label="被邀请人累计体验次数"
        prop="cumulativeExperienceCount"
        width="160px"
      />
      <el-table-column
        label="邀请人获得奖励"
        prop="cumulativeReward"
        width="160px"
      />
      <el-table-column
        label="累计提现金额"
        prop="cumulativeWithdrawalAmount"
        width="160px"
      />
      <el-table-column
        label="累计兑换金币"
        prop="cumulativeExchangeGold"
        width="160px"
      />
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  getInviteFriendsStatisticalList,
  getInviteFriendsStatistical
} from '@/api/friends.js'

export default {
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      statisticsLoading: false,
      statisticsInfo: {},
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      statusMap: {
        1: '待处理',
        2: '处理成功',
        3: '处理失败'
      },
      tempData: {}
    }
  },
  created () {
    this.handleSearch()
    this.queryOrderStatics()
  },
  methods: {
    queryOrderStatics () {
      this.statisticsLoading = true
      getInviteFriendsStatistical({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      getInviteFriendsStatisticalList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
    .withdrawal {
      .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
</style>
