import request from '@/utils/request'

export function getInviteFriendsStatistical (data) {
  return request({
    url: '/count/getInviteFriendsStatistical',
    data
  })
}

export function getInviteFriendsStatisticalList (data) {
  return request({
    url: '/count/getInviteFriendsStatisticalList',
    data
  })
}

export function getAllWithdrawRecord (data) {
  return request({
    url: '/pay/getAllWithdrawRecord',
    data
  })
}

export function getWithdrawStatistical (data) {
  return request({
    url: '/pay/getWithdrawStatistical',
    data
  })
}

export function updateWithdrawStatus (data) {
  return request({
    url: '/pay/updateWithdrawStatus',
    data
  })
}
